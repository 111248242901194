@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiDateCalendar-root .Mui-selected {
  background-color: #f8b864 !important;
}
.MuiDateCalendar-root .Mui-selected:hover {
  background-color: #f8b864 !important;
}

.MuiDateCalendar-root .MuiButtonBase-root,
.MuiDateCalendar-root .MuiTypography-root {
  font-weight: 600 !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border: 2px solid #f8b864 !important;
}
